import React from 'react';

function App() {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Hola, soy Juan Serrano</h1>
      <h2>Estoy probando herramientas de google analytics y publicidad con sus metricas</h2>
    </div>
  );
}

export default App;
